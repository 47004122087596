import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProjectHelse = ({ data, location }) => {
  return (
    <Layout>
      <SEO title="Chalalai, desarrollo de sitio web Drupal con reservas y pagos" description="Chalalai es un centro de masaje tailandés de Madrid para el que desarrollamos el sitio web con gestión de reservas y pagos online para sus tratamientos y masajes."/>
      <section className="jumbotron pt-3 pb-6 mb-0" style={{backgroundColor: '#E9E0D0'}}>
        <div className="hero-zone container">
          <div className="hero-text row justify-content-md-center">
            <div className="col-lg-6">
              <h1 className="font-weight-bold diplay-4">Chalalai Thai Massage</h1>
              <p className="h3">Desarrollo de sitio web con pasarela de pago y gestión de reservas para centro de masaje tailandés en Madrid.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-3 bg-light text-left" id="features">
        <div className="container pb-5">
          <div className="row text-left">
            <div className="col-md-8 col-sm-12">
              <span className="badge badge-pill badge-secondary p-2 mr-2">Desarrollo Drupal</span>
              <span className="badge badge-pill badge-secondary p-2 m-2">Migración SEO</span>
              <span className="badge badge-pill badge-secondary p-2 m-2">Diseño Visual</span>
              <span className="badge badge-pill badge-secondary p-2 m-2 mb-4">UX</span>
              <h2>
                <strong className="text-left pt-3">Puntos clave</strong>
              </h2>
              <div className="lead pt-4">
                <strong className="texto-destacado">Reserva de masajes.</strong>
                <p>Los usuarios pueden reservar un masaje o tratamiento a través de la web, integrándose con la plataforma de gestión de reservas para conocer las disponibilidades de huecos para realizar la reserva.</p>
              </div>
              <div className="lead pt-4">
                <strong className="texto-destacado">Pasarela de pago.</strong>
                <p>Cuando un usuario realiza una reserva de masaje o tratamiento, pueden elegir pagar en el local o bien realizar el pago online en el caso de que sea un regalo. Desarrollo de módulo custom para integrarnos con el banco con el que trabajan.</p>
              </div>
              <div className="lead pt-4">
                <strong className="texto-destacado">Multi-idioma.</strong>
                <p>La web soporta multi-idioma con navegación paralela y SEO-friendly URLs.</p>
              </div>
              <div className="lead pt-4">
                <strong className="texto-destacado">Campañas de tráfico de pago.</strong>
                <p>Definimos una serie de campañas en Google Ads tanto en inglés como en castellano que hemos ido optimizando regularmente, y puntualmente realizamos campañas de Facebook Ads y email-marketing.</p>
              </div>
              <div className="lead pt-4 pb-5">
                <strong className="texto-destacado">Tracking de KPIs con Google Data Studio.</strong>
                <p>El cliente dispone de un panel de control en Google Data Studio que integra datos de diferentes fuentes (Google Analytics, Google Ads y datasheets automatizados) que les permite trackear todos los KPIs importantes para su negocio, y el impacto de los cambios que hacemos en la web o en las campañas de PPC.</p>
              </div>
            </div>
            <div className="col-md-3 offset-md-0 offset-lg-1 col-sm-12">
              <div className="colaboradores">
                <h2 className="pt-lg-5">
                  <strong className="text-left pt-0">Colaboradores</strong>
                </h2>
              </div>
              <p className="pt-3 client-desc">En este proyecto colaboramos con <a href="https:somosfractal.com/" target="_blank" rel="noopener noreferrer">Fractal</a>, que hicieron la conceptualización del proyecto y toda la parte visual (Diseño visual y UX)</p>
              <div className="cliente-proyecto">
                <h2 className="pt-5">
                  <strong className="text-left pt-0">Cliente</strong>
                </h2>
              </div>
              <p className="pt-3 client-desc">Centro de masaje y spá tailandés en el centro de Madrid, desarrollo de la web cuando se creó el centro, desde entonces más de 5000 reservas a través de la web.</p>
              <div className="btn-project">
                <a className="btn mr-4 btn-lg btn-outline-primary" href="https://chalalai.es/">Sitio web →</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="jumbotron pb-5 text-left bg-white pt-0 mt-n6" id="features">
        <div className="container">
          <div className="row text-left">
            <div className="col-md-12 col-12 col-sm-12">
              <h2 className="pt-4 pb-4">
                <strong>El proyecto en imágenes</strong>
              </h2>
            </div>
            <div className='embed-responsive embed-responsive-16by9'>
               <iframe className="project-video embed-responsive-item" title="Video" width={1200} height={674} src="https://player.vimeo.com/video/412514324?autoplay=1&loop=1&autopause=0&muted=1&background=1"></iframe>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ProjectHelse
